  <div class="py-5" style="background-image: url(assets/img/logo/tlo.jpg); background-position: right bottom;  background-size: cover; background-repeat: repeat; background-attachment: fixed;">
    <div class="container">
      <div class="row">
        <div class="ml-auto p-3 col-md-2 bg-transparent text-dark"> <img class="img-fluid d-block" src="assets/img/logo/gambling-dice.svg"> </div>
        <div class="p-3 col-md-7 mr-auto bg-transparent">
          <div class="blockquote mb-0">
            <p>"Człowiek jest jedyną istotą, która gra dla przyjemności"</p>
            <div class="blockquote-footer"> <b>Johan Huizinga</b>, antropolog kultury</div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="py-3">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <img class="card-img" [src]='hdrlogo.imgurl' alt="Header">
          <div class="card-img-overlay">
            <h1 class="text-primary text-center">{{ doc.subject }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="py-1">
  <div class="container">
    <div class="row">
      <div class="py-2 col-md-11 bg-white">
        <p class="text-primary text-right">{{ doc.created }}</p>
      </div>
      <div class="col-md-12">
        <div [innerHTML]="doc.text | safeHtml"></div>
      </div>
    </div>
  </div>
</div>
