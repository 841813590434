import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { GeneralService } from './../_services/general.service';
import { AppStatus } from './../_models/appstatus';
import { DocEntry } from './../_models/docentry.model';
import { DeImage } from './../_models/deimage.model';

@Component({
  selector: 'app-fo-documentview',
  templateUrl: './fo-documentview.component.html',
  styleUrls: ['./fo-documentview.component.scss']
})
export class FoDocumentviewComponent implements OnInit
{
  constructor(private route: ActivatedRoute, private router: Router, private ges: GeneralService, private apst: AppStatus)
  { }

  fodocv_wherefrom: string;
  fodocv_id: string;
  doc: DocEntry;
  hdrlogo: DeImage;
  figures: DeImage[];
  bckgnd: DeImage;

  loadDoc(id: string)
  {
    this.ges.doLoadDoc(id).toPromise().then(data =>
    {
      this.doc = Object.assign([], data);
      this.doc.text = this.ges.escapeBackslashes(this.doc.text);
      this.hdrlogo = this.ges.findOneDeImageByAttr(this.doc.images, "hdrlogo");
      this.figures = this.ges.findDeImagesByAttr(this.doc.images, "figure");
      this.bckgnd = this.ges.findOneDeImageByAttr(this.doc.images, "bckgnd");
    });
  }

  ngOnInit(): void
  {
    if (this.apst.fodocv_wherefrom === undefined)
    {
      this.route.queryParamMap.pipe(
        map(() => window.history.state)).subscribe(params =>
        {
          this.apst.fodocv_wherefrom = params.wherefrom;
          this.apst.fodocv_id = params.id;
          this.fodocv_wherefrom = this.apst.fodocv_wherefrom;
          this.fodocv_id = this.apst.fodocv_id;
      });
    } else {
      this.fodocv_wherefrom = this.apst.fodocv_wherefrom;
      this.fodocv_id = this.apst.fodocv_id;
    }

    if (this.apst.fodocv_id !== undefined)
    {
      this.loadDoc(this.apst.fodocv_id);
    } 
    else 
    {
      this.router.navigateByUrl('/home');
    }
  }
}
