  <div class="py-5" style="background-image: url(assets/img/logo/tlo.jpg); background-position: right bottom;  background-size: cover; background-repeat: repeat; background-attachment: fixed;">
    <div class="container">
      <div class="row">
        <div class="ml-auto p-3 col-md-2 bg-transparent text-dark"> <img class="img-fluid d-block" src="assets/img/logo/gambling-dice.svg"> </div>
        <div class="p-3 col-md-7 mr-auto bg-transparent">
          <div class="blockquote mb-0">
            <p>"Człowiek jest jedyną istotą, która gra dla przyjemności"</p>
            <div class="blockquote-footer"> <b>Johan Huizinga</b>, antropolog kultury</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-3 text-center">
    <div class="container">
      <div class="row">
        <div class="mx-auto p-4 col-lg-7">
          <h1 class="mb-4">Kontakt</h1>
          <form>
            <div class="form-row">
              <div class="form-group col-md-6"> <input type="text" class="form-control" id="form27" placeholder="Name"> </div>
              <div class="form-group col-md-6"> <input type="email" class="form-control" id="form28" placeholder="Email"> </div>
            </div>
            <div class="form-group"> <input type="text" class="form-control" id="form29" placeholder="Subject"> </div>
            <div class="form-group"> <textarea class="form-control" id="form30" rows="3" placeholder="Your message"></textarea> </div> <button type="submit" class="btn btn-primary">Wyślij</button>
          </form>
        </div>
      </div>
    </div>
  </div>