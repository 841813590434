<div class="py-5" style="background-image: url(https://static.pingendo.com/cover-bubble-dark.svg); background-position: right bottom;  background-size: cover; background-repeat: repeat; background-attachment: fixed;">
    <div class="container">
      <div class="row">
        <div class="ml-auto p-3 col-md-2 bg-white"> <img class="img-fluid d-block rounded-circle" src="https://static.pingendo.com/img-placeholder-1.svg"> </div>
        <div class="p-3 col-md-7 mr-auto bg-white text-dark">
          <div class="blockquote mb-0">
            <p>"Człowiek jest jedyną istotą, która gra dla przyjemności"</p>
            <div class="blockquote-footer"> <b>Johan Huizinga</b>, antropolog kultury</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-5 text-center" style="background-image: url('https://static.pingendo.com/cover-bubble-dark.svg');background-size:cover;">
    <div class="container">
      <div class="row">
        <div class="mx-auto col-md-6 col-10 bg-white p-5">
          <h1 class="mb-4">Logowanie</h1>
          <form [formGroup]="signinForm" (ngSubmit)="signin()">
            <div class="form-group"> 
              <input type="email" class="form-control" formControlName="email" placeholder="Email" id="input-email"> 
            </div>
            <div class="form-group mb-3"> 
              <input type="password" class="form-control" formControlName="password" placeholder="Hasło" id="input-password"> 
            </div> 
            <button type="submit" class="btn btn-primary">Zaloguj się</button>
          </form>
        </div>
      </div>
    </div>
  </div>