  <div class="py-5" style="background-image: url(assets/img/logo/tlo.jpg); background-position: right bottom;  background-size: cover; background-repeat: repeat; background-attachment: fixed;">
    <div class="container">
      <div class="row">
        <div class="ml-auto p-3 col-md-2 bg-transparent text-dark"> <img class="img-fluid d-block" src="assets/img/logo/gambling-dice.svg"> </div>
        <div class="p-3 col-md-7 mr-auto bg-transparent">
          <div class="blockquote mb-0">
            <p>"Człowiek jest jedyną istotą, która gra dla przyjemności"</p>
            <div class="blockquote-footer"> <b>Johan Huizinga</b>, antropolog kultury</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <mdb-carousel [isControls]="false" [animation]="'slide'" id="crsl-1">
              <mdb-carousel-item *ngFor="let image of images">
                <img class="d-block w-100" [src]="image" alt="Random slide">
              </mdb-carousel-item>
          </mdb-carousel>
        </div>
      </div>
    </div>
  </div>
  <div class="py-3 text-center">
    <div class="container">
      <div class="row">
        <div class="p-4 col-lg-4 col-md-6 text-black-50" *ngFor="let md of mpishforsec1 | slice:0:dsis1; let i=index">
          <h4><b>{{ md.subject }}</b></h4>
          <p class="text-body" [innerHTML]="md.text | safeHtml"></p>
          <p>
            <a [routerLink]="['/docv']" [state]="{ wherefrom: 'mp', id: md.id }">Czytaj dalej</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="py-5" *ngIf="blogSections>1">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <mdb-carousel [isControls]="false" [animation]="'slide'" id="crsl-2">
            <mdb-carousel-item *ngFor="let image of images">
              <img class="d-block w-100" [src]="image" alt="Random slide">
            </mdb-carousel-item>
          </mdb-carousel>
        </div>
      </div>
    </div>
  </div>
  <div class="py-3 text-center" *ngIf="blogSections>1">
    <div class="container">
      <div class="row">
        <div class="p-4 col-lg-4 col-md-6" *ngFor="let md of mpishforsec2 | slice:dsis1:dsis2; let i=index">
          <h4><b>{{ md.subject }}</b></h4>
          <p [innerHTML]="md.text | safeHtml"></p>
          <p>
            <a [routerLink]="['/docv']" [state]="{ wherefrom: 'mp', id: md.id }">Czytaj dalej</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="py-5" *ngIf="blogSections>2">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <mdb-carousel [isControls]="false" [animation]="'slide'" id="crsl-3">
            <mdb-carousel-item *ngFor="let image of images">
              <img class="d-block w-100" [src]="image" alt="Random slide">
            </mdb-carousel-item>
          </mdb-carousel>
        </div>
      </div>
    </div>
  </div>
  <div class="py-3 text-center" *ngIf="blogSections>2">
    <div class="container">
      <div class="row">
        <div class="p-4 col-lg-4 col-md-6" *ngFor="let md of mpishforsec3 | slice:dsis2:dsis3; let i=index">
          <h4><b>{{ md.subject }}</b></h4>
          <p [innerHTML]="md.text | safeHtml"></p>
          <p>
            <a [routerLink]="['/docv']" [state]="{ wherefrom: 'mp', id: md.id }">Czytaj dalej</a>
          </p>
        </div>
      </div>
    </div>
  </div>