<div class="bg-secondary text-black-50 py-3">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center d-md-flex justify-content-between align-items-center">
          <ul class="nav d-flex justify-content-center">
            <li class="nav-item"> <a class="nav-link active" routerLink="/home">Witamy</a> </li>
            <li class="nav-item"> <a class="nav-link" routerLink="/abt">O blogu</a> </li>
            <!-- 
            <li class="nav-item"> <a class="nav-link" href="#">Regulamin</a> </li>
            <li class="nav-item"> <a class="nav-link" href="#">Polecamy</a> </li>
            -->
          </ul>
          <p class="mb-0 py-1">© vBit 2021. Wszystkie prawa zastrzeżone.</p>
        </div>
      </div>
    </div>
</div>
