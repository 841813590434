import { Injectable } from '@angular/core';
import { Mpi } from './mpi.model';
import { DocEntry } from './docentry.model';

@Injectable({
  providedIn: 'root',
})
export class AppStatus
{  
  fodocv_wherefrom: string;
  fodocv_id: string;

  fodocl_wherefrom: string;

  mpis: Mpi[];
  mpidocs: DocEntry[];

  constructor() { }
}