import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from './../_services/general.service';
import { DocEntry } from './../_models/docentry.model';
import { User } from './../_models/user.model';
import { AppStatus } from './../_models/appstatus';

@Component({
  selector: 'app-fo-main',
  templateUrl: './fo-main.component.html',
  styleUrls: ['./fo-main.component.scss'],
  providers: [NgbCarouselConfig]
})
export class FoMainComponent implements OnInit
{
  showNavigationArrows = false;
  showNavigationIndicators = false;
  images = [21, 22, 23, 24].map((n) => `assets/img/img_${n}.jpg`);

  blogSections = 1;
  dsis1 = 3;
  dsis2 = this.dsis1 * 2;
  dsis3 = this.dsis1 * 3;

  mpishforsec1: DocEntry[];
  mpishforsec2: DocEntry[];
  mpishforsec3: DocEntry[];

  user: User;

  wherefrom: string;
  status: string;

  constructor(private config: NgbCarouselConfig, private ges: GeneralService,
    private apst: AppStatus, private usr: User, private route: ActivatedRoute)
  {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  sectionscopy(de: DocEntry[], secno: number): DocEntry[]
  {
    let i = 0;
    let cnt = 1;
    let ret = [];
    let b: string;
    const len = de.length;

    if (len > 0)
    {
      b = de[0].bid;

      while ((cnt <= secno) && (i < len))
      {
        if (b === de[i].bid)
        {
          if (secno === cnt)
          {
            ret[i] = {};
            for (const prop in de[i]) 
            {
              ret[i][prop] = de[i][prop];
            }
          }
          i++;
        } else {
          b = de[i].bid;
          cnt++;
        }
      }
    }
    return ret;
  }

  mpiTextTrim(de: DocEntry[], txtsize: number): void
  {
    let i = 0;
    const len = de.length;

    if (len > 0)
    {
      for (i = 0; i < len; i++)
      {
        const s = this.ges.truncate(de[i].text, txtsize, true);
        de[i].text = s;
      }
    }
  }

  loadMpiDocsP()
  {
    if (this.apst.mpidocs === undefined)
    {
      this.ges.doLoadMpiDocs().toPromise().then(data =>
      {
        this.apst.mpidocs = Object.assign([], data);
        this.ges.sanitizeDEarray(this.apst.mpidocs);
        this.mpishforsec1 = this.sectionscopy(this.apst.mpidocs, 1);
        this.mpiTextTrim(this.mpishforsec1, 250);
      });
    } else {
      this.mpishforsec1 = this.sectionscopy(this.apst.mpidocs, 1);
      this.mpiTextTrim(this.mpishforsec1, 250);
    }
  }

  ngOnInit(): void
  {
    const uss = sessionStorage.getItem('k1tk9');
    if (uss)
    {
      this.usr = JSON.parse(uss);
      this.user = this.usr;
    }

    this.route.queryParams.pipe(
      filter(params => params.wherefrom)).subscribe(params => {
        this.wherefrom = params.wherefrom;
        this.status = params.status;
    });

    if (sessionStorage.length > 0)
    {
      this.ges.nmon_SignInOut.next('Wyloguj');
    }
    else
    {
      this.ges.nmon_SignInOut.next('Zaloguj');
    }

    this.ges.clearRoutingParams();

    this.loadMpiDocsP();
  }
}
