<nav class="navbar navbar-expand-lg bg-primary navbar-dark">
    <div class="container">
      <a class="navbar-brand" routerLink="/home">
        <img class="img-fluid d-block" src="assets/img/logo/logo_col_nb.svg" alt="Gamgeo" height="50" width="130">
      </a>
      <button class="navbar-toggler" type="button" (click)="collapsed = !collapsed"
        data-toggle="collapse" data-target="#navbarsEx" 
        aria-controls="navbarsEx" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" [ngbCollapse]="collapsed" id="navbarsEx">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" routerLink="/home">Witamy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/docl']" 
              [state]="{ wherefrom: 'link', nrb: 0, page: 1, pagesize: 3, docount: 1 }">Blog</a>
          </li>
          <li class="nav-item" ngbDropdown>
            <a class="nav-link" style="cursor: pointer" ngbDropdownToggle 
              id="navbarDropdown1" role="button">Menu
            </a>
            <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
              <div *ngIf="signingInMenu">
                <div *ngIf="optionSgnInOut === 'Zaloguj'">
                  <a ngbDropdownItem routerLink="/sgn" (click)="$event.preventDefault()">{{ optionSgnInOut }}</a>
                </div>
              </div>
              <div *ngIf="optionSgnInOut === 'Wyloguj'">
                <a ngbDropdownItem routerLink="/mngm" (click)="$event.preventDefault()">Zaplecze</a>
                <a ngbDropdownItem (click)="signout()">{{ optionSgnInOut }}</a>
              </div>
              <a ngbDropdownItem routerLink="/abt" (click)="$event.preventDefault()">O blogu</a>
            </div>
          </li>
        </ul>
        <form class="form-inline my-2 my-md-0 py-2">
          <input class="form-control" type="text" placeholder="Search" aria-label="Search">
          <button class="btn btn-link my-2 my-sm-0" type="submit"><i class="fa d-inline fa-lg fa-search text-primary"></i></button>
        </form>
      </div>
    </div>
  </nav>