import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { GeneralService } from './../_services/general.service';
import { AppStatus } from './../_models/appstatus';
import { DocEntry } from './../_models/docentry.model';
import { DeImage } from './../_models/deimage.model';
import { User } from './../_models/user.model';
import { Blog } from './../_models/blog.model';

  /*
    https://stackoverflow.com/questions/38544503/accessing-specific-array-element-in-an-angular2-template
    Uwaga kontrola w szablonie HFML dot. wyświetlania docicon lub mocków nie jest poprawna.
  */

@Component({
  selector: 'app-fo-doclist',
  templateUrl: './fo-doclist.component.html',
  styleUrls: ['./fo-doclist.component.scss']
})
export class FoDoclistComponent implements OnInit
{
  availBlogs: Blog[];
  num: number;
  deList: DocEntry[];
  images: Array<DeImage>;  // https://stackoverflow.com/questions/14680740/how-to-instantiate-initialize-and-populate-an-array-in-typescript
  collectionSize: number;
  nrb: number;
  page: number;
  pageSize: number;
  pagesCount: number;
  doCount: number;
  bid: string;
  uid: string;

  constructor(private route: ActivatedRoute, private router: Router, private ges: GeneralService,
              private apst: AppStatus, private usr: User)
  { }

  loadData(uid: string, nrb: number, pageno: number, pagesize: number, docount: number, month: number, year: number)
  {
    this.ges.doLoadAvailableBlogs(uid).pipe(map(ablogs => {
      const ablog = ablogs[nrb];
      this.availBlogs = Object.assign([], ablogs);
      this.num = this.availBlogs.length;
      this.bid = ablog.id;
      return ablog;
    }),
    mergeMap(ablog => this.ges.doLoadBlogList(ablog.id, uid, pageno, pagesize, docount, month, year)))
      .subscribe(data => {
        this.deList = Object.assign([], data);
        let i = 0;
        let tmp: DeImage[];
        const len = this.deList.length;

        if (len > 0)
        {
          while (i < len)
          {
            tmp = this.ges.findDeImagesByAttr(this.deList[i].images, "docicon");
            const tmplen = tmp.length;  // w 'tmp' ma być tylko jeden obrazek ikony
            if (tmplen > 0)             // czyli tmp[0]
            {
              if (i === 0) {  // Object.assign(this.images[i], tmp[0]);  ==> ERROR  tak se ne da
                this.images = Array(tmp[0]);
              } else {
                this.images.push(tmp[0]);
              }
            }
            i++;
          }
        }

        this.collectionSize = this.deList[0].bdCnt;
        this.pagesCount = Math.floor(this.collectionSize / pagesize);
        const remnants = this.collectionSize % pagesize;

        if (remnants > 0)
        {
          this.pagesCount = this.pagesCount + 1;
        }
      }
    );
  }

  getPage(page: any)
  {
    if (!Number.isInteger(page))
    {
      if (page === 'prev')
      {
        this.page = this.page - 1;
        if (this.page === 0)
        {
          this.page = 1;
        }
      }
      if (page === 'next')
      {
        this.page = this.page + 1;
        if (this.page > this.pagesCount)
        {
          this.page = this.pagesCount;
        }
      }
    } else {
      this.page = page;
    }

    this.ges.doLoadBlogList(this.bid, this.uid, this.page - 1, this.pageSize, 0, 0, 0)
      .subscribe(data =>
        this.deList = Object.assign([], data)
      )
  }

  ngOnInit(): void
  {
    this.ges.clearRoutingParams();

    this.route.queryParamMap.pipe(
      map(() => window.history.state)).subscribe(params =>
      {
        this.apst.fodocl_wherefrom = params.wherefrom;
        this.nrb = params.nrb;
        this.page = params.page;
        this.pageSize = params.pagesize;
        this.doCount = params.docount;
    });

    if (this.apst.fodocl_wherefrom === undefined)
    {
      this.router.navigateByUrl('/home');
    }

    if (Object.keys(this.usr).length === 0)
    {
      const uss = sessionStorage.getItem('k1tk9');
      if (uss)
      {
        this.usr = JSON.parse(uss);
      }
    }

    if (Object.keys(this.usr).length > 0)
    {
      this.uid = this.usr.id;
    } else {
      this.uid = 'null';
    }

    if (this.apst.fodocl_wherefrom === 'link')
    {
      this.loadData(this.uid, this.nrb, this.page - 1,
        this.pageSize, this.doCount, 0, 0);
    }
  }
}
