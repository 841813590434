import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { FoNavbarComponent } from './fo-navbar/fo-navbar.component';
import { FoFooterComponent } from './fo-footer/fo-footer.component';
import { FoMainComponent } from './fo-main/fo-main.component';
import { FoAboutComponent } from './fo-about/fo-about.component';
import { FoDocumentviewComponent } from './fo-documentview/fo-documentview.component';
import { FoSigninComponent } from './fo-signin/fo-signin.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FoDoclistComponent } from './fo-doclist/fo-doclist.component';
import { LimitPipePipe } from './_pipes/limit-pipe.pipe';
import { SafeHtmlPipe } from './_pipes/safe-html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    FoNavbarComponent,
    FoFooterComponent,
    FoMainComponent,
    FoAboutComponent,
    FoDocumentviewComponent,
    FoSigninComponent,
    PageNotFoundComponent,
    FoDoclistComponent,
    LimitPipePipe,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    NgbModule,
    MDBBootstrapModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
