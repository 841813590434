import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginguardService } from './_services/loginguard.service';
import { FoMainComponent } from './fo-main/fo-main.component';
import { FoDoclistComponent } from './fo-doclist/fo-doclist.component';
import { FoDocumentviewComponent } from './fo-documentview/fo-documentview.component';
import { FoSigninComponent } from './fo-signin/fo-signin.component';
import { FoAboutComponent } from './fo-about/fo-about.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BoMainComponent } from './bo-main/bo-main.component';

const routes: Routes = [
  { path: 'home', component: FoMainComponent },
  { path: 'docl', component: FoDoclistComponent },
  { path: 'docv', component: FoDocumentviewComponent },
  { path: 'sgn', component: FoSigninComponent },
  { path: 'abt', component: FoAboutComponent },
  { path: 'mngm', component: BoMainComponent, canActivate: [LoginguardService] },
  { path: '', component: FoMainComponent },
  { path: '**', component: FoMainComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
