<div class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul class="list-group">
          <a *ngFor="let de of deList; index as i"
              [routerLink]="['/docv']" [state]="{ wherefrom: 'blog', id: de.id }"
              class="list-group-item list-group-item-action list-group-item-light d-flex justify-content-between align-items-center">
            <div class="flex-column">
              {{ de.subject }}<p><small>{{ de.username }}</small></p><p><small>{{ de.created }}</small></p>
            </div>
            <div class="image-parent">
              <div *ngIf="images?.length && i < images?.length; then realDocIcon else mockDocIcon"></div>
              <ng-template #realDocIcon>
                <img class="img-fluid d-block rounded-circle" [src]='images[i].imgurl' alt="icon">
              </ng-template>
              <ng-template #mockDocIcon>
                <img src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/don_quixote.jpg" class="img-fluid" alt="mock">
              </ng-template>
            </div>
          </a>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="py-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12"> <div class="py-3 text-center" *ngIf="pagesCount > 1">

        <ul class="pagination">

          <li class="page-item"> <a class="page-link" (click)="getPage('prev')" aria-label="Previous"> <span aria-hidden="true">&lt;</span> <span class="sr-only"></span> </a> </li> <!-- <span aria-hidden="true">«</span> -->

          <div *ngIf="page === 1; then activeFirst else notActiveFirst"></div>
          <ng-template #activeFirst>
            <li class="page-item active"> <a class="page-link" (click)="getPage(1)">1</a></li>
          </ng-template>
          <ng-template #notActiveFirst>
            <li class="page-item"> <a class="page-link" (click)="getPage(1)">1</a></li>
          </ng-template>

          <div *ngIf="pagesCount > 2">

            <div *ngIf="page > 2">
              <li class="page-item"> <a class="page-link">...</a> </li>
            </div>

            <div *ngIf="page !== 1 && page !== pagesCount">
              <li class="page-item active"> <a class="page-link">{{ page }}</a></li>
            </div>

            <div *ngIf="page < pagesCount - 1">
              <li class="page-item"> <a class="page-link">...</a> </li>
            </div>

          </div>

          <div *ngIf="page === pagesCount; then activeLast else notActiveLast"></div>
          <ng-template #activeLast>
            <li class="page-item active"> <a class="page-link" (click)="getPage(pagesCount)">{{ pagesCount }}</a></li>
          </ng-template>
          <ng-template #notActiveLast>
            <li class="page-item"> <a class="page-link" (click)="getPage(pagesCount)">{{ pagesCount }}</a></li>
          </ng-template>

          <li class="page-item"> <a class="page-link" (click)="getPage('next')" aria-label="Next"> <span aria-hidden="true">&gt;</span> <span class="sr-only"></span> </a> </li>  <!-- <span aria-hidden="true">»</span> -->

        </ul>
      </div>
    </div>
  </div>
</div>
