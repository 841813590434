import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitPipe'
})
export class LimitPipePipe implements PipeTransform 
{
  transform(value: any, args?: any): any 
  {
    return null;
  }
}
