import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { GeneralService } from './../_services/general.service';
import { User } from './../_models/user.model';

@Component({
  selector: 'app-fo-navbar',
  templateUrl: './fo-navbar.component.html',
  styleUrls: ['./fo-navbar.component.scss']
})
export class FoNavbarComponent implements OnInit
{
  collapsed = true;
  signingInMenu = false;
  optionSgnInOut: any;
  signoutsubs: Subscription;

  constructor(private ges: GeneralService, private usr: User, protected router: Router)
  {  }

  signout(): void
  {
    if (Object.keys(this.usr).length > 0)
    {
      sessionStorage.clear();
      this.signoutsubs = this.ges.doSignout(this.usr.email, this.usr.password)
        .pipe(first()).subscribe(
          (data) => {
            this.usr = undefined;
            this.router.navigateByUrl('/home');
          },
          (error) => {
            this.usr = undefined;
            this.router.navigateByUrl('/home');
          }
      );
    }
  }

  ngOnInit(): void
  {
    this.ges.nmon_SignInOut.subscribe(name => this.optionSgnInOut = name);
  }
}
