import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class User
{
  id: string;
  regdate: string;
  username: string;
  fullname: string;
  email: string;
  password: string;
  passwdexptime: string;
  enabled: number;
  scrambled: number;
  imei: string;
  ip: string;
  status: string;
  role: string;
  pocket: number;
  tid: string;
  avatar: string;
  currvisit: string;
  lastvisit: string;
  loginattempts: number;
  inactivereason: number;
  inactivetime: string;
  permissions: string;
  kid: number;
	atr1: string;
	val1: string;
	atr2: string;
	val2: string;
	atr3: string;
	val3: string;
	atr4: string;
	val4: string;
	atr5: string;
	val5: string;
	atr6: string;
  val6: string;
  
  constructor()
  { }
}