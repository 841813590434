import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { User } from './../_models/user.model';
import { GeneralService } from './../_services/general.service';

@Component({
  selector: 'app-fo-signin',
  templateUrl: './fo-signin.component.html',
  styleUrls: ['./fo-signin.component.scss']
})
export class FoSigninComponent implements OnInit
{
  signinForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl('')
  });

  signinsubs: Subscription;
  signoutsubs: Subscription;
  user$: Observable<User>;

  constructor(private ges: GeneralService, private usr: User, protected router: Router)
  {
  }

  ngOnInit(): void
  {
  }

  signin(): void
  {
    this.signinsubs = this.ges.doSignin(this.signinForm.get('email').value,
                     this.signinForm.get('password').value)
    .pipe(first())
    .subscribe(
      (data) => {
        if (sessionStorage.length > 0)
        {
          this.signoutsubs = this.ges.doSignout(this.usr.email, this.usr.password)
            .pipe(first()).subscribe(
              (outcome) => {
                sessionStorage.clear();
                this.usr = undefined;
              }
          );
        }
        sessionStorage.setItem('k1tk9', JSON.stringify(data));
        Object.assign(this.usr, data);
        this.router.navigate(['/home'], {queryParams: { wherefrom: 'sgn', status: 'OK' }});
       },
      (error) => {
        this.usr = undefined;
        this.router.navigate(['/home'], {queryParams: { wherefrom: 'sgn', status: 'ERR' }});
      });
  }

  signoutandin(): void
  {
    if (sessionStorage.length > 0)
    {
      forkJoin(
        this.ges.doSignout(this.usr.email, this.usr.password),
        this.ges.doSignin(this.signinForm.get('email').value,
            this.signinForm.get('password').value)
      ).subscribe(
        ([u1, u2]: [User, User]) => {
          sessionStorage.clear();
          sessionStorage.setItem('k1tk9', JSON.stringify(u2));
          Object.assign(this.usr, u2);
          this.router.navigate(['/home'], {queryParams: { wherefrom: 'sgn', status: 'OK' }});
        }
      );
    }
    else
    {
      this.ges.doSignin(this.signinForm.get('email').value,
            this.signinForm.get('password').value)
      .pipe(first())
      .subscribe(
        (data) => {
          sessionStorage.setItem('k1tk9', JSON.stringify(data));
          Object.assign(this.usr, data);
          this.router.navigate(['/home'], {queryParams: { wherefrom: 'sgn', status: 'OK' }});
        },
        (error) => {
          this.usr = undefined;
          this.router.navigate(['/home'], {queryParams: { wherefrom: 'sgn', status: 'ERR' }});
        });
    }
  }

 signin_simple(): void
 {
   this.user$ = this.ges.doSignin(this.signinForm.get('email').value,
    this.signinForm.get('password').value);
 }
}
