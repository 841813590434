import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginguardService implements CanActivate
{
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean
  {
    if (sessionStorage.length > 0)
    {
      return true;
    }
    else
    {
      alert('You are not allowed to view this page');
      this.router.navigateByUrl('/home', { queryParams: { return: state.url } });
      return false;
    }
  }
}
