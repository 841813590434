import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable, Subject } from 'rxjs';
import { filter, first, map, toArray } from 'rxjs/operators';
import { Mpi } from './../_models/mpi.model';
import { Blog } from './../_models/blog.model';
import { User } from './../_models/user.model';
import { DocEntry } from './../_models/docentry.model';
import { DeImage } from './../_models/deimage.model';
import { AppStatus } from './../_models/appstatus';

@Injectable({
  providedIn: 'root'
})
export class GeneralService
{
  BVS = 'http://77.55.193.102:17017';  // 'http://77.55.193.102:17017' || 'http://localhost:17017'
  cid = encodeURI('webclient');
  csec = encodeURI('uxdatinrypcjqxh');
  bas = 'Basic ' + window.btoa(this.cid + ':' + this.csec);
  headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set('Authorization', this.bas);

  public nmon_SignInOut = new Subject();
  public nmon_BackOffice = new Subject();

  constructor(private httpc: HttpClient, private apst: AppStatus, private user: User)
  { }

  doSignin(email: string, password: string): Observable<User>
  {
    const url = this.BVS + '/bvi/user/sin';
    const body = { email, password };
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: this.bas
      })
    }
    return this.httpc.put<User>(url, body, httpOptions);
  }

  doSignout(email: string, password: string): Observable<User>
  {
    const url = this.BVS + '/bvi/user/sout';
    const body = { email, password };
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: this.bas
      })
    }
    return this.httpc.put<User>(url, body, httpOptions);
  }

  doLoadDoc(did: string): Observable<DocEntry>
  {
    const url = this.BVS + '/bvi/doc/' + did;
    return this.httpc.get<DocEntry>(url, { headers: this.headers });
  }

  doLoadMpiDocs(): Observable<DocEntry[]>
  {
    const url = this.BVS + '/bvi/doc/docsonmpi';
    return this.httpc.get<DocEntry[]>(url, { headers: this.headers });
  }

  doLoadMpis(): Observable<Mpi[]>
  {
    const url = this.BVS + '/bvi/doc/mpi';
    return this.httpc.get<Mpi[]>(url, { headers: this.headers });
  }

  doLoadAvailableBlogs(uid: string): Observable<Blog[]>
  {
    const url = this.BVS + '/bvi/doc/blg/avail/' + uid;
    return this.httpc.get<Blog[]>(url, { headers: this.headers });
  }

  doLoadBlogList(bid: string, uid: string, pageno: number, itemscnt: number, docount: number, month: number, year: number): Observable<DocEntry[]>
  {
    const url = this.BVS + '/bvi/doc/blg/list';
    const pagination = { pageno: pageno, itemscnt: itemscnt, docount: docount, uid: uid, bid: bid, month: month, year: year };

    return this.httpc.post<DocEntry[]>(url, pagination, { headers: this.headers });
  }

  // toolset

  clearRoutingParams(): void
  {
    this.apst.fodocv_wherefrom = undefined;
    this.apst.fodocv_id = undefined;
  }

  deepcopy(aObject: any): any
  {
    if (!aObject)
    {
      return aObject;
    }

    let v;
    let bObject: any = Array.isArray(aObject) ? [] : {};

    for (const k in aObject) 
    {
      v = aObject[k];
      bObject[k] = (typeof v === "object") ? this.deepcopy(v) : v;
    }
    return bObject;
  }

  cleanObject(o: any): void
  {
    const boolDefault = false;
    const stringDefault = '';
    const numberDefault = 0;

    for (const [key, value] of Object.entries(o))
    {
      const propType = typeof(o[key]);
      switch (propType)
      {
        case 'number' :
          o[key] = numberDefault;
          break;

        case 'string':
          o[key] = stringDefault;
          break;

        case 'boolean':
          o[key] = boolDefault;
          break;

        case 'undefined':
          o[key] = undefined;
          break;

        default:
          if (value === null)
          {
            continue;
          }
          this.cleanObject(o[key]);
          break;
      }
    }
  }

  truncate(str: string, n: number, useWordBoundary: boolean): string
  {
    let singular: boolean;
    const tooLong: boolean = str.length > n;
    useWordBoundary = useWordBoundary || true;

    str = tooLong ? str.substr(0, n - 1) : str;

    singular = (str.search(/\s/) === -1) ? true : false;
    if (!singular)
    {
      str = useWordBoundary && tooLong ? str.substr(0, str.lastIndexOf(' ')) : str;
    }

    return tooLong ? str + ' …' : str;
  }

  escapeBackslashes(str: string): string
  {
    return str.replace(/\\/g, '');
  }

  sanitizeDEarray(de: DocEntry[]): void
  {
    let i = 0;
    const len = de.length;

    if (len > 0)
    {
      for (i = 0; i < len; i++)
      {
        const s = this.escapeBackslashes(de[i].text);
        de[i].text = s;
      }
    }
 }
 
 /*  Odnalezienie w tablicy dokumentów dokumentu o podanym 'id'.
  *  Wersja deklaratywna. Wykorzystywana na stronie głównej.
  */
 findDocEntryById(delist: DocEntry[], id: string): DocEntry
 {
   let ret: DocEntry;

   const apsmpids$: Observable<DocEntry> = from(delist);
   apsmpids$.pipe(filter(doc => doc.id === id)).subscribe(val => ret = val);
   return ret;
 }

 /*  Odnalezienie w tablicy dokumentów dokumentu o podanym 'id'.
  *  Wersja imperatywna, nie wykorzystywana nigdzie. Kod pozostał dla celów poglądowych.
  */
 findDocEntryById_oldStyle(delist: DocEntry[], id: string): DocEntry
 {
   let i = 0;
   let ret: DocEntry;
   const len = delist.length;

   if (len > 0)
   {
     while (i < len)
     {
       if (this.apst.mpidocs[i].id === id)
       {
         ret = delist[i];
         break;
       }
       i++;
     }
     return ret;
   }
 }

   /*
    https://stackoverflow.com/questions/51898005/rxjs6-filter-array-of-objects
    https://www.learnrxjs.io/learn-rxjs/operators/transformation/toarray
    https://stackoverflow.com/questions/44513990/rxjs-observable-transform-array-to-multiple-values
    https://stackoverflow.com/questions/48136430/how-to-filter-an-array-with-rxjs-operator
    https://stackoverflow.com/questions/36984059/rxjs-array-of-observable-to-array
    https://www.techiediaries.com/javascript-reactive-asynchronous-code-rxjs-6-angular-10/
    https://www.learnrxjs.io/
    http://reactivex.io/rxjs/manual/tutorial.html#tutorials
    https://www.tutorialspoint.com/rxjs/rxjs_quick_guide.htm
    https://stackoverflow.com/questions/51898005/rxjs6-filter-array-of-objects
  */

 /*  Odnalezienie w tablicy obrazków o podanej wartości 'attr'.
  *  Wersja deklaratywna. Wykorzystywana na stronie głównej.
  */
  findDeImagesByAttr(dilist: DeImage[], attr: string): DeImage[]
  {
    let ret: DeImage[];
    let odi$ = from(dilist);

    odi$.pipe(
      map(images => images), filter(di => di.attr === attr), toArray())
      .subscribe(val => ret = Object.assign([], val));
    return ret;
  }

 /*  Odnalezienie w tablicy pojedynczego obrazka o podanej wartości 'attr'.
  *  Wersja deklaratywna. Wykorzystywana w widoku treści artykułu.
  */
   findOneDeImageByAttr(dilist: DeImage[], attr: string): DeImage
   {
     let ret: DeImage;
     let odi$ = from(dilist);
 
     odi$.pipe(
       map(images => images), filter(di => di.attr === attr), first())
       .subscribe(val => ret = Object.assign(val));
     return ret;
   }
 
}

/* kod tutorialowy, którego nie wykorzystałem
  getData = () => {
    const books = [
      { id: 1, title: 'Dreams of yesterday', groupId: 123 },
      { id: 2, title: 'Just hear about an earthquake', groupId: 124 },
      { id: 3, title: 'I lost what I have left', groupId: 125 },
      { id: 4, title: 'I feel I am helping you', groupId: 123 },
      { id: 5, title: 'All the thing I wanna do', groupId: 124 },
      { id: 6, title: 'If you are not here', groupId: 125 },
    ];

    return from(books);
  }

  $data = this.getData();

  afunc(): void
  {
    let log: any[];

    this.$data.pipe(
      groupBy(book => book.groupId), mergeMap(group$ => group$.pipe(
        reduce((acc, cur) =>[...acc, cur], [])
      ))
    ).subscribe(val => log = val);
  }
*/